import "./public_path"

import "whatwg-fetch"
import "es6-promise/auto"


import * as React from "react";
import {lazy, Suspense} from "react";
import * as ReactDOM from 'react-dom';
import URLParams from "sancus-client-common/dist/utils/utils";
import {publicURL,getPublicURLPromise, WindowAttributes} from "sancus-client-common/dist/common/contextPath"

import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";





declare global{
    interface Window extends WindowAttributes{}
}

const { fetch: originalFetch } = window;

const isInframe = window.parent !== window;


let preferSessionHeader = window.preferSessionHeader ?? false;

if (isInframe){
    let parentSameOrigin;
    try{
        parentSameOrigin = window.parent.location.origin === window.location.origin;
    }catch (e){
        parentSameOrigin = false;
    }
    preferSessionHeader = preferSessionHeader || !parentSameOrigin;
}

if (preferSessionHeader){
    //Need to set session id in header as cookeis are 3rd party
    window.fetch = (...args) => {
        let [resource, config ] = args;

        if (config == null){
            config = {}
        }
        if (config.headers == null){
            config.headers = {}
        }

        config.headers['X-SANCUS-SESSION-ID'] = window.sancus_session_id;

        return originalFetch(resource,config);
    }
}



const urlParams = URLParams();

let theme_variant:string | null | undefined = urlParams.theme;
if (theme_variant == null){
    theme_variant = window.sancus?.theme;
}
if (theme_variant == null){
    try{
        theme_variant = window.parent.sancus?.theme;
    }catch (e){
        console.debug('Failed to get theme from parent window')
    }
}
if (theme_variant == null){
    theme_variant = 'default';
}

console.log("Using theme variant",theme_variant);


const themePromise = getPublicURLPromise().then((p_url)=>fetch(
    `${p_url}themes/theme_${theme_variant}.json`
).then(rsp => {
        try {
            return rsp.json();
        } catch (e) {
            console.error(e);
            return {}
        }
    }
).catch(e => {
    console.error(e);
    return {}
}));

const ThemedFormContainer = lazy(() => {
    return import(/*webpackChunkName:"enter-sancus" */ "./enter_sancus")
});
const useEnterStyles=  makeStyles(()=>{
    return {
        suspense: sessionStorage.getItem('sancus-suspense')  && JSON.parse(sessionStorage.getItem('sancus-suspense')!) || {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw'
        }
    }
});
const Sancus = (params)=>{
    const enter_styles = useEnterStyles();
    return <Suspense fallback={
        <div id={"suspense"}
             className={"suspense "+enter_styles.suspense}
        >
            {window.sancus?.please_wait_msg}
            <CircularProgress/>
        </div>
    }>
        <ThemedFormContainer {...params} urlParams={urlParams} theme_data={params.theme_data} style={{width:'100%'}}/>
    </Suspense>
}

const inIframe=  window.parent  && window.parent !== window;


document.body.dataset.inIframe = inIframe.toString();
const formContainer = document.getElementById("formContainer");
if (formContainer){
    formContainer.dataset.inIframe = inIframe.toString();
}else{
    console.error("formContainer not found")
}

const allowedLSearchParams = ['key','processDefinitionKey', 'processDefinitionId', 'processInstanceId', 'taskId', 'noStep', 'showSections', 'hideStepper', 'displayLogin','displayLanguageSelector', 'useInternalGlobalLoader','renderActionContainer']

export async function enter(params?:any) {

    let l_search_params = URLParams();
    if (params == null){
        params = {};
    }

    const variables = {

    }
    Object.keys(l_search_params).forEach(( pk) => {
        const pv = l_search_params[pk];
        if (pv!=null) {
            if (allowedLSearchParams.indexOf(pk) !== -1) {
                params[pk] = pv;
            } else {
                variables[pk] = pv;
            }
        }
    })


    if (!params['processDefinitionKey'] && !params['processDefinitionId']) {
        if(l_search_params.key) {
            params['processDefinitionKey'] = l_search_params.key;
        }
        else if (params['processInstanceId'] || params['taskId']){
            //TODO: get from process instance or task

        }else{
            params['processDefinitionKey'] = "create_new_user";
        }
    }

    if(l_search_params.hasOwnProperty("lang")) {
        params['lang'] = l_search_params.lang;
    } else {
        params['lang'] = 'en';
    }

    if (params.variables){
        Object.assign(params.variables,variables)
    }else{
        params.variables = variables
    }

    return themePromise.then(theme_data=>new Promise<void>(resolve => {
        if (params['displayLogin'] === 'true' || params['displayLogin'] === true){
            params['displayLogin'] = true;
        }else{
            delete params['displayLogin']
        }

        if (params['displayLanguageSelector'] === 'true' || params['displayLanguageSelector'] === true || window.sancus?.languages){
            params['displayLanguageSelector'] = true;
            if (window.sancus?.languages){
                params['languages'] = window.sancus.languages;
            }
        }else {
            delete params['displayLanguageSelector']
        }
        ReactDOM.render(<Sancus {...params} theme_data={theme_data}/>,document.getElementById("formContainer"),()=>{
            resolve();
        });
    }));
}

//Make sure it is exported
window.enter = enter;
