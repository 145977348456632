console.log("ContextPath",process.env.CONTEXT_PATH);

export type Color = 'red' | 'green' | 'yellow' | 'blue'
export type Sancus  ={
     query:{
          key:string,
          sancus_session_id?:string,
          query_string?:string
     },
     theme: string,
     languages: string,
     please_wait_msg: string
     overrides:Partial<{
          avatar:string,
          avatar_alt:string,
          service_name:string,
          color:Color,
          exportFilesThreshold:number,
          exportFileParallelism:number

     }>

}

export type WindowAttributes = {
     sancus?: Sancus;
     enter: () => Promise<unknown>;
     sancus_session_id?: string;
     publicURL?:string;
     contextBaseUri?:string;
     contextPath?:string;
     preferSessionHeader?:boolean;
}


declare global{
  interface Window extends WindowAttributes{
  }
}

function findContextPath(){
    //FIXME: This would obvious not work with chrome extensions, stored or mobile apps or if we would decouple backend from frontend in any case
    /*
    if (window && window.location) {
        //Pathnames start with / we need the first part of this
        const idx = window.location.pathname.indexOf("/", 1)
        return window.location.pathname.substring(0, idx + 1);
    }else{
        return process.env.CONTEXT_PATH || "http://localhost:8080/"
    }
    */
     // return "https://int-demo.norbloc.com/sancus-dev/";
     return window?.contextPath || process.env.CONTEXT_PATH  || process.env.REACT_APP_CONTEXT_PATH || "/" ;
}

let _contextPath = findContextPath()
const cpp = {
     get contextPath(){
          // console.log('_contextPath',_contextPath)
          return _contextPath;
     },
     set contextPath(value){
          setContextPath(value)
     },
     toString(){
       return _contextPath
     },

     get publicURL(){
          let _publicURL= window.publicURL || process.env.PUBLIC_URL || cpp.contextPath;
          return _publicURL.endsWith("/")?_publicURL : (_publicURL+"/");
     },

     get contextBaseUri(){
          const _cp = this.contextPath;
          return _cp.substring(_cp.length-1);
     }
}

export const setContextPath =  (value)=>{
     console.log('setContextPath',value)
     if(!value.endsWith('/')) {
          value = value + '/';
     }
     _contextPath = value;
};

export default cpp;


export function getPublicURLPromise() {
     const pu = window.publicURL || process.env.PUBLIC_URL;
     if (pu) {
          return Promise.resolve(pu);
     } else {
          return new Promise((resolve, reject) => {
               setTimeout(() => {
                    getPublicURLPromise().then(resolve).catch(reject)
               }, 1000)
          });
     }
}


let _publicURL= window.publicURL || process.env.PUBLIC_URL || cpp.contextPath;
export const publicURL = _publicURL.endsWith("/")?_publicURL : (_publicURL+"/");

export const contextBaseUri = window.contextBaseUri || process.env.REACT_APP_CONTEXT_BASE_URI || "";
